import React from 'react'

const Cases = () => {
    return (
        <div className='flex justify-between items-center mt-20 pb-6 w-full h-screen md:h-1/2 lg:h-1/2'>
            <div className=' flex flex-col justify-between items-start sm:flex-row md:flex-row md:items-center md:pt-48 px-32 lg:mt-22 lg:pb-4 w-full h-20'>
                <h2 className='text-2xl md:text-2xl font-montserrat uppercase font-thin'>Cases jeg er <span className='text-[#6D8A7E]'>stolt af</span></h2>
                <a className='px-4 py-2 bg-[#005146] text-[#3BE186] text-sm rounded-full uppercase font-montserrat font-semibold' href="#">Se alle cases</a>
            </div>
        </div>
    )
}

export default Cases
