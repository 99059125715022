import React from 'react';
import Cases from './Cases';
import Cards from './Cards';

const Main = () => {
    return (
        <main>
            <section>
                <Cases />
                <Cards />
            </section>
        </main>
    );
}

export default Main;
