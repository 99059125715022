import React from 'react'
import LogoImage from '../assets/logo.png';

const Logo = () => {
    return (
        <div className='z-50 bg-[#005146] w-16 h-screen flex-shrink-0 flex-grow-0'>
            <div className='h-screen pt-4 pl-2 bg-[#005146] fixed w-16'>
                <img className=' w-12' src={LogoImage} alt="Logo" />
            </div>
        </div>

    )
}

export default Logo
