import React from 'react'

const Navbar = () => {
    return (
        <nav className='flex justify-between w-full'>
            <nav className='hidden ml-auto p-6 text-xs md:flex font-montserrat font-semibold'>
                <ul className='flex items-center gap-4 text-white uppercase'>
                    <li className='px-6'><a href="#">Forside</a></li>
                    <li className='px-6'><a href="#">Cases</a></li>
                    <li className='px-6'><a href="#">Om mig</a></li>
                    <li className='px-6 py-2 bg-[#005146] text-[#3BE186] rounded-full'><a href="#">Kontakt</a></li>
                </ul>
            </nav>
        </nav>
    )
}

export default Navbar
