import React from 'react'
import SkillCards from './SkillCards'
import SkillText from './SkillText'

const SkillsSection = () => {
  return (
    <div>
      <SkillText />
      <SkillCards />
    </div>
  )
}

export default SkillsSection
