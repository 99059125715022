import React from 'react'

const SkillText = () => {
    return (
        <div className='flex flex-col w-full h-2/3 bg-[#0D2925]'>
            <div className='flex flex-col justify-center items-start mr-auto px-32 pt-28'>
                <h2 className='text-2xl text-white md:text-2xl font-montserrat uppercase font-thin'>Mere end 10.000 <span className='text-[#6C897E]'>Timers erfaring</span></h2>
            </div>
        </div>
    )
}

export default SkillText
