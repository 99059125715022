import React from "react";
import "./App.css";
import Header from "./components/Header";
import Main from "./components/Main";
import SkillsSection from "./components/SkillsSection";
import AboutFrontPage from "./components/AboutFrontPage";
import Slider from "./components/Slider";
import Testimonials from "./components/Testimonials";

function App() {
  return (
    <div className="App grid-cols-2">
      <main className="relative">
        <Header />
        <Main />
        <SkillsSection />
        <AboutFrontPage />
        <Slider />
        <Testimonials />
      </main>
    </div>
  );
}

export default App;
