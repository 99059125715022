import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';


const TestimonialsText = () => {
    return (
        <div className='flex flex-row justify-start items-center text-left gap-6 w-full h-64 px-32 bg-[#0D2925] font-montserrat'>
            <div>
                <h2 className='text-2xl uppercase text-white font-light'>Anmeldelser fra <span className='text-[#6D8A7E] whitespace-nowrap'>kunderne</span></h2>
            </div>
            <div className='flex flex-row gap-2'>
                <FontAwesomeIcon icon={faStar} className="text-green-300" />
                <FontAwesomeIcon icon={faStar} className="text-green-300" />
                <FontAwesomeIcon icon={faStar} className="text-green-300" />
                <FontAwesomeIcon icon={faStar} className="text-green-300" />
                <FontAwesomeIcon icon={faStar} className="text-green-300" />
            </div>
        </div>
    )
}

export default TestimonialsText
