import React from 'react';

const AboutFrontText = () => {
    return (
        <div className="relative flex flex-col justify-between items-start w-full h-2/3 mx-auto bg-custom-image bg-cover bg-center bg-fixed font-montserrat">

            <div className="absolute inset-0 bg-black opacity-70"></div>

            <div className="relative z-10 flex flex-col justify-center items-start text-left px-32 w-full h-2/3 text-white py-40">
                <h2 className='text-2xl uppercase'>Kort om mig</h2>
                <p className='py-4 text-lg leading-8'>Her skal der stå en <span className='text-green-300'>kort</span> tekst Her skal der stå en kort tekst <br /> Her skal der stå en kort tekst Her skal der stå en kort tekst <br /> Her skal der stå en kort tekst Her skal der stå en <span className='text-green-300'>kort</span> tekst</p>
                <a className='px-4 py-2 bg-[#005146] text-[#3BE186] text-sm rounded-full uppercase font-montserrat font-semibold' href="#">Min karriere</a>
            </div>
        </div>
    );
};

export default AboutFrontText;
