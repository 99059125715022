import React from 'react'
import TestimonialsText from './TestimonialsText'
import TestimonialsCard from './TestimonialsCard'

const Testimonials = () => {
    return (
        <div>
            <TestimonialsText />
            <TestimonialsCard />
        </div>
    )
}

export default Testimonials
