import React from 'react'
import Forside from '../assets/forside.jpg'
import { TypeAnimation } from 'react-type-animation';


const Hero = () => {

    return (
        <div className='flex flex-col md:flex-row justify-between items-center w-full absolute top-56 mx-auto'>
            <div className='flex flex-col justify-center items-start ml-52 h-16 w-full md:w-2/3 md:-mr-8 lg:-mr-10'>
                <h1 className='text-left -mt-44 text-white text-4xl md:-ml-20 lg:-ml-10 lg:text-5xl font-thin font-montserrat'>
                    Jeg er <br />
                    <span className='text-green-300'>
                        <TypeAnimation
                            sequence={[
                                'Frontend-developer',
                                2000,
                                'Google Ads Specialist',
                                2000,
                                'Multimedie-designer',
                                2000,
                                () => {
                                    console.log('Sequence completed');
                                },
                            ]}
                            wrapper="span"
                            cursor={true}
                            repeat={Infinity}
                            style={{ fontSize: '1em', display: 'inline-block' }}
                        />
                    </span>
                </h1>
                <div className='mt-8 md:-ml-20 lg:-ml-10'>
                    <button className='px-8 py-4 text-sm text-white border-2 border-white font-semibold rounded-full hover:bg-white hover:text-green-900 transition duration-300'>
                        DOWNLOAD CV
                    </button>
                </div>
            </div>
            <div className='flex justify-center items-center ml-16 w-2/3 md:mr-16 md:w-1/3 lg:mr-36'>
                <img className='max-w-96 object-cover rounded-xl md:-mt-16 md:max-w-72 lg:max-w-96' src={Forside} alt="Frontend-Udvikler" />
            </div>
        </div>
    )
}

export default Hero
