import React from 'react';

// En genanvendelig komponent til SliderCard
const SliderCard = ({ number, title, description }) => {
    return (
        <div className="flex-shrink-0 w-full pb-20 sm:w-2/3 md:w-1/3 font-montserrat">
            <div className="flex flex-row items-start w-full h-full">
                <div>
                    <h2 className='px-2 text-[8em] sm:text-[10em] md:text-[12em] text-[#5EB9B3]'>{number}</h2>
                </div>
                <div className='flex flex-col justify-start items-start text-left pt-20'>
                    <h3 className='text-lg sm:text-xl uppercase font-light pb-3'>{title}</h3>
                    <p className='text-xs leading-6'>{description}</p>
                </div>
            </div>
        </div>
    );
};

// Parent-komponent som indeholder alle kortene
const SliderCards = () => {
    return (
        <div className="sm:overflow-hidden">
            <div className="overflow-x-scroll flex space-x-6 sm:space-x-10 md:space-x-20 snap-x snap-mandatory px-32 cursor-pointer">
                <SliderCard
                    number="1"
                    title="Kreativitet"
                    description="Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst"
                />
                <SliderCard
                    number="2"
                    title="Forretningsforståelse"
                    description="Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå"
                />
                <SliderCard
                    number="3"
                    title="Kvalitet"
                    description="Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå"
                />
                <SliderCard
                    number="4"
                    title="Gode resultater"
                    description="Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal"
                />
            </div>
        </div>
    );
}

export default SliderCards;
