import React from 'react'
import SliderHeader from './SliderHeader'
import SliderCard from './SliderCard'

const Slider = () => {
    return (
        <div>
            <SliderHeader />
            <SliderCard />
        </div>
    )
}

export default Slider
