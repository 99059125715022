import React from 'react'
import Logo from './Logo'
import Navbar from './Navbar'
import Hero from './Hero'
import Sidebar from './Sidebar'

const Header = () => {
    return (
        <header className='flex flex-col md:flex-row justify-between items-start bg-[#0D2925] h-screen'>
            <div className='flex flex-col md:flex-row justify-center items-start w-full'>
                <Logo />
                <Navbar />
                <Sidebar />
            </div>
            <Hero />
        </header>
    )
}

export default Header
