import React, { useState } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Sidebar = () => {
    const [nav, setNav] = useState(false);

    const handleNav = () => {
        setNav(!nav);
    };

    return (
        <aside className={`fixed top-0 right-0 md:hidden lg:hidden h-screen font-montserrat font-semibold z-50 transition-colors duration-500 ease-in-out ${nav ? 'bg-[#005146]' : 'bg-transparent'}`}>
            <div onClick={handleNav} className='flex justify-end items-center text-green-300 p-4 cursor-pointer'>
                {!nav ? <RxHamburgerMenu size={20} /> : <IoIosCloseCircleOutline size={20} />}
            </div>
            <div className={`transition-all ease-in-out duration-500 transform ${nav ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'} max-h-screen n overflow-hidden flex-col text-left px-28 py-6 text-white uppercase`}>
                <ul>
                    <li className='py-6 border-[#6B897E] border-b'><a href="#">Forside</a></li>
                    <li className='py-6 border-[#6B897E] border-b'><a href="#">Cases</a></li>
                    <li className='py-6 border-[#6B897E] border-b'><a href="#">Om mig</a></li>
                    <li className='mx-auto mt-6 py-3 text-center w-52 bg-[#0D2925] text-[#3BE186] rounded-full'><a href="#">Kontakt</a></li>
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
