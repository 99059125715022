import React from 'react';
import BambusworldImage from '../assets/bambusworld.png';
import FsImage from '../assets/fsholstebro.png';
import LægehusImage from '../assets/lægehus.png';

const Cards = () => {
    return (
        <div className='w-full h-2/3 mt-0 bg-[#0D2925]'>
            <div className='grid grid-cols-1 bg-[#0D2925] sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 ml-12 md:ml-20 px-20 sm:pl-12 md:pl-26 lg:pl-56 md:mt-72'>

                <div className='relative flex-col justify-start items-start text-left px-6 py-8 h-96 rounded-xl bg-[#5EB9B3] col-span-1 -mt-60 sm:-mt-56 md:-mt-56 lg:-mt-52'>
                    <h1 className='text-lg font-montserrat text-white font-thin uppercase'>Bambusworld</h1>
                    <p className='text-white font-montserrat font-thin text-sm py-2'>Dette er bare en random tekst</p>
                    <img src={BambusworldImage} alt='Bambusworld' className='absolute top-10 right-0 w-56 h-auto object-contain rounded-xl' />
                    <div className='absolute bottom-4 left-6'>
                        <button className='px-6 py-2 bg-[#0D2925] text-[#3BE186] text-xs font-montserrat font-bold uppercase rounded-full'>Læs mere</button>
                    </div>
                </div>

                <div className='relative flex-col justify-start items-start text-left px-6 py-8 h-96 rounded-xl bg-[#6B897E] col-span-1 sm:-mt-56 md:-mt-56 lg:-mt-52'>
                    <h1 className='text-lg font-montserrat text-white font-thin uppercase'>Fs Holstebro</h1>
                    <p className='text-white font-montserrat font-thin text-sm py-2'>Dette er bare en random tekst</p>
                    <img src={FsImage} alt='Fs Holstebro' className='absolute bottom-0 right-0 w-64 h-auto object-contain rounded-xl' />
                    <div className='absolute bottom-4 left-4'>
                        <button className='px-6 py-2 bg-[#0D2925] text-[#3BE186] text-xs font-montserrat font-bold uppercase rounded-full'>Læs mere</button>
                    </div>
                </div>

                <div className='relative flex-col justify-start items-start text-left px-6 py-8 h-96 rounded-xl bg-[#778077] col-span-1 lg:-mt-52'>
                    <h1 className='text-lg font-montserrat text-white font-thin uppercase'>Ringkøbing lægehus</h1>
                    <p className='text-white font-montserrat font-thin text-sm py-2'>Dette er bare en random tekst</p>
                    <img src={LægehusImage} alt='Ringkøbing lægehus' className='absolute bottom-0 right-0 w-full h-auto object-contain rounded-xl' />
                    <div className='absolute bottom-4 left-4'>
                        <button className='px-6 py-2 bg-[#0D2925] text-[#3BE186] text-xs font-montserrat font-bold uppercase rounded-full'>Læs mere</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cards;
