import React from 'react';

// En genanvendelig komponent til TestimonialsCard
const TestimonialsCard = ({ subtitle, title, description }) => {
    return (
        <div className="flex-shrink-0 w-full sm:w-2/3 md:w-1/3 p-4">
            <div className="flex flex-col justify-center items-start text-left h-full border-2 border-green-300 rounded-lg p-10 bg-[#005146] font-montserrat">
                <h3 className="text-sm text-[#1FFCB3] font-light uppercase">{subtitle}</h3>
                <h2 className="text-2xl text-[#1FFCB3] font-medium uppercase">{title}</h2>
                <p className="text-white py-4">{description}</p>
            </div>
        </div>
    );
};

// Parent-komponent som indeholder alle testimonials kortene
const TestimonialsSlider = () => {
    return (
        <div className="sm:overflow-hidden w-full pl-32 pb-20 bg-[#0D2925]">
            <div className="overflow-x-scroll flex space-x-6 sm:space-x-10 md:space-x-20 snap-x snap-mandatory cursor-pointer">
                <TestimonialsCard
                    subtitle="BambusWorld"
                    title="Henrik Nielsen"
                    description="Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst"
                />
                <TestimonialsCard
                    subtitle="FS Holstebro"
                    title="Morten Hedegaard"
                    description="Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst"
                />
                <TestimonialsCard
                    subtitle="WebDevPro"
                    title="Charlotte Hansen"
                    description="Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst"
                />
                <TestimonialsCard
                    subtitle="DesignHub"
                    title="Anders Madsen"
                    description="Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst Her skal der stå en kort tekst"
                />
            </div>
        </div>
    );
};

export default TestimonialsSlider;
