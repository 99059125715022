import React from 'react'

const SliderHeader = () => {
    return (
        <div className='flex flex-col justify-start items-start text-left uppercase w-full h-1/2 font-montserrat'>
            <div className='flex flex-col px-32 pt-20'>
                <h3 className='text-lg text-[#5EB9B3] font-extralight py-4'>Arbejdstilgang</h3>
                <h2 className='text-3xl'>Mine 4 grundprincipper</h2>
            </div>
        </div>
    )
}

export default SliderHeader
