import React from 'react'
import WebPage from '../assets/webpage.png'
import Google from '../assets/google.png'
import Cloud from '../assets/cloud.png'
import Kamera from '../assets/kamera.png'

const SkillCards = () => {
    return (
        <section className='flex flex-col justify-between items-center w-full h-2/3 py-20 bg-[#0D2925]'>
            <div className='grid grid-cols-1 justify-between mx-auto gap-28 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 h-2/3'>
                <div className='flex flex-col justify-center'>
                    <div className='w-16 h-16 bg-[#005146] rounded-lg'>
                        <div className='flex flex-col w-16 h-16 justify-center items-center'>
                            <img className='w-10 h-10  object-contain' src={WebPage} alt="webpage" />
                        </div>
                    </div>
                    <div className='flex flex-col items-start my-4'>
                        <h3 className='text-md font-montserrat font-semibold text-white uppercase'>Frontend udvikling</h3>
                        <p className='text-sm text-white font-montserrat font-thin my-1'>Her skal der stå en kort tekst</p>
                    </div>
                </div>
                <div className='flex flex-col justify-center'>
                    <div className='w-16 h-16 bg-[#005146] rounded-lg'>
                        <div className='flex flex-col w-16 h-16 justify-center items-center'>
                            <img className='w-10 h-10  object-contain' src={Google} alt="webpage" />
                        </div>
                    </div>
                    <div className='flex flex-col items-start my-4'>
                        <h3 className='text-md font-montserrat font-semibold text-white uppercase'>Google Ads</h3>
                        <p className='text-sm text-white font-montserrat font-thin my-1'>Her skal der stå en kort tekst</p>
                    </div>
                </div>
                <div className='flex flex-col justify-center'>
                    <div className='w-16 h-16 bg-[#005146] rounded-lg'>
                        <div className='flex flex-col w-16 h-16 justify-center items-center'>
                            <img className='w-10 h-10  object-contain' src={Kamera} alt="webpage" />
                        </div>
                    </div>
                    <div className='flex flex-col items-start my-4'>
                        <h3 className='text-md font-montserrat font-semibold text-white uppercase'>Video Content</h3>
                        <p className='text-sm text-white font-montserrat font-thin my-1'>Her skal der stå en kort tekst</p>
                    </div>
                </div>
                <div className='flex flex-col justify-center'>
                    <div className='w-16 h-16 bg-[#005146] rounded-lg'>
                        <div className='flex flex-col w-16 h-16 justify-center items-center'>
                            <img className='w-10 h-10  object-contain' src={Cloud} alt="webpage" />
                        </div>
                    </div>
                    <div className='flex flex-col items-start my-4'>
                        <h3 className='text-md font-montserrat font-semibold text-white uppercase'>Adobe</h3>
                        <p className='text-sm text-white font-montserrat font-thin my-1'>Her skal der stå en kort tekst</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SkillCards
